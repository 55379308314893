import axios from 'axios'
import axiosRetry from 'axios-retry';

axiosRetry( axios, {
  retries: 3,
  retryDelay: ( retryCount ) => {
    return 0;
  }
} );

// Add a request interceptor
axios.interceptors.request.use(
  (config) => {
    const token = localStorage.getItem( 'vue-session-key' ) ? JSON.parse( localStorage.getItem( 'vue-session-key' ) ).tokenSession : ''
    config.baseURL = process.env.VUE_APP_API_DIRECTORY
    config.headers.get[ 'Accept' ] = 'application/json'
    // config.headers.get[ 'Origin' ] = process.env.VUE_APP_WEBSITE
    config.headers.get[ 'Content-Type' ] = 'application/json'
    config.headers.get[ 'X-Requested-With' ] = 'XMLHttpRequest'
    config.headers.get[ 'Access-Control-Allow-Origin' ] = process.env.VUE_APP_WEBSITE
    config.headers.get[ 'Access-Control-Allow-Methods' ] = 'GET, POST, PATCH, PUT, DELETE, OPTIONS'
    config.headers.get[ 'Access-Control-Allow-Headers' ] = 'Origin, Content-Type, X-Requested-With, Accept'
    config.headers.post[ 'Accept' ] = 'application/json'
    // config.headers.get[ 'Origin' ] = process.env.VUE_APP_WEBSITE
    config.headers.post[ 'Content-Type' ] = 'application/json'
    config.headers.post[ 'X-Requested-With' ] = 'XMLHttpRequest'
    config.headers.post[ 'Access-Control-Allow-Origin' ] = process.env.VUE_APP_WEBSITE
    config.headers.post[ 'Access-Control-Allow-Methods' ] = 'GET, POST, PATCH, PUT, DELETE, OPTIONS'
    config.headers.post[ 'Access-Control-Allow-Headers' ] = 'Origin, Content-Type, X-Requested-With, Accept'
    config.headers.common[ 'Authorization' ] = 'Bearer ' + token
    return config
  },
  (error) => {
    return Promise.reject(error)
  }
)

export default axios
