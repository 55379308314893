const AdminendPanel = () => import( 'Layout/adminPanel/AdminPanel' )

// dashboard components
const Dashboard = () => import('Views/adminPages/Dashboard');
const Profile = () => import( 'Views/adminPages/Profile' );
const Companies = () => import( 'Views/adminPages/Companies' );
const Users = () => import( 'Views/adminPages/Users' );
const Data = () => import( 'Views/adminPages/Data' );
const CompanyData = () => import( 'Views/adminPages/CompanyData' );
const Advertisements = () => import( 'Views/adminPages/Advertisements' );
const UserAdvertisements = () => import( 'Views/adminPages/externalUsers/UserAdvertisements' );
const UserCompanies = () => import( 'Views/adminPages/externalUsers/Companies' );
const CompanyGalery = () => import( 'Views/adminPages/CompanyGalery' );
const FormRequests = () => import( 'Views/adminPages/FormRequests' );
const UserFormRequests = () => import( 'Views/adminPages/externalUsers/FormRequests' );
const Publications = () => import( 'Views/adminPages/Publications' );
const Categories = () => import( 'Views/adminPages/Categories' );
const Glosary = () => import( 'Views/adminPages/Glosary' );
const Sitemap = () => import( 'Views/adminPages/Sitemap' );
const Statistics = () => import( 'Views/adminPages/Statistics' );

export default {
   path: '/admin',
   name: 'AdminendPanel',
   component: AdminendPanel,
   redirect: '/admin/panel',
   meta: {
      title: 'Admin',
      requiresAuth: true,
      requiredRoles: [ 'admin', 'super_admin', 'user' ]
   },
   children: [
      {
         path: '/admin/panel', name: 'Dashboard',
         component: Dashboard,
         meta: {
            title: 'Dashboard',
            requiresAuth: true,
            requiredRoles: [ 'admin', 'super_admin', 'user' ]
         }
      },
      /*{
         path: '/admin/messages', name: 'Messages',
         component: Messages,
         meta: {
            title: 'Messages',
            requiresAuth: true,
            requiredRoles: ['admin', 'super_admin']
         }
      },
      {
         path: '/admin/bookings', name: 'Bookings',
         component: Bookings,
         meta: {
            title: 'Bookings',
            requiresAuth: true,
            requiredRoles: ['admin', 'super_admin']
         }
      },
      {
         path: '/admin/reviews', name: 'Reviews',
         component: Reviews,
         meta: {
            title: 'Reviews',
            requiresAuth: true,
            requiredRoles: ['admin', 'super_admin']
         }
      },
      {
         path: '/admin/bookmarks', name: 'Bookmarks',
         component: Bookmarks,
         meta: {
            title: 'Bookmarks',
            requiresAuth: true,
            requiredRoles: ['admin', 'super_admin']
         }
      },
      {
         path: '/admin/list', name: 'List',
         component: List,
         meta: {
            title: 'List',
            requiresAuth: true,
            requiredRoles: ['admin', 'super_admin']
         }
      },
      {
         path: '/admin/add-list', name: 'AddList',
         component: AddList,
         meta: {
            title: 'Add List',
            requiresAuth: true,
            requiredRoles: ['admin', 'super_admin']
         }
      },*/
      {
         path: '/admin/perfil', name: 'Profile',
         component: Profile,
         meta: {
            title: 'Perfil',
            requiresAuth: true,
            requiredRoles: ['admin', 'super_admin', 'user', 'advertiser', 'manager']

         }
      },
      {
         path: '/admin/usuarios', name: 'Users',
         component: Users,
         meta: {
            title: 'Usuarios',
            requiresAuth: true,
            requiredRoles: ['super_admin']
         }
      },
      {
         path: '/admin/franquicias', name: 'Franchises',
         component: Companies,
         meta: {
            title: 'Franquicias',
            requiresAuth: true,
            requiredRoles: ['admin', 'super_admin']
         },
         props: {
            companyType: 0
         }
      },
      {
         path: '/admin/asociaciones-franquicias', name: 'FranchiseAssociations',
         component: Companies,
         meta: {
            title: 'Asociaciones de Franquicias',
            requiresAuth: true,
            requiredRoles: ['admin', 'super_admin']
         },
         props: {
            companyType: 1
         }
      },
      {
         path: '/admin/consultores', name: 'Consultants',
         component: Companies,
         meta: {
            title: 'Consultores',
            requiresAuth: true,
            requiredRoles: ['admin', 'super_admin']
         },
         props: {
            companyType: 2
         }
      },
      {
         path: '/admin/proveedores', name: 'Providers',
         component: Companies,
         meta: {
            title: 'Proveedores',
            requiresAuth: true,
            requiredRoles: ['admin', 'super_admin']
         },
         props: {
            companyType: 3
         }
      },
      {
         path: '/admin/oferta-inmobiliaria', name: 'RealEstateOffer',
         component: Companies,
         meta: {
            title: 'Oferta Inmobiliaria',
            requiresAuth: true,
            requiredRoles: ['admin', 'super_admin']
         },
         props: {
            companyType: 4
         }
      },
      {
         path: '/admin/empresas/ficha', name: 'CompaniesData',
         component: CompanyData,
         meta: {
            title: 'Ficha de Franquicia',
            requiresAuth: true,
            requiredRoles: ['admin', 'super_admin']
         }
      },
      {
         path: '/admin/datos', name: 'Data',
         component: Data,
         meta: {
            title: 'Datos',
            requiresAuth: true,
            requiredRoles: ['super_admin']
         }
      },
      {
         path: '/admin/publicidad', name: 'Advertisements',
         component: Advertisements,
         meta: {
            title: 'Publicidad',
            requiresAuth: true,
            requiredRoles: ['admin', 'super_admin']
         }
      },
      {
         path: '/admin/cliente/publicidad', name: 'UserAdvertisements',
         component: UserAdvertisements,
         meta: {
            title: 'Publicidad',
            requiresAuth: true,
            requiredRoles: ['admin', 'super_admin', 'advertiser']
         }
      },
      {
         path: '/admin/cliente/franquicias', name: 'UserFranchises',
         component: UserCompanies,
         meta: {
            title: 'Franqucias',
            requiresAuth: true,
            requiredRoles: ['admin', 'super_admin', 'manager']
         },
         props: {
            companyType: 0
         }
      },
      {
         path: '/admin/cliente/asociaciones-franquicias', name: 'UserAsociations',
         component: UserCompanies,
         meta: {
            title: 'Asociaciones de Franquicias',
            requiresAuth: true,
            requiredRoles: [ 'admin', 'super_admin', 'manager']
         },
         props: {
            companyType: 1
         }
      },
      {
         path: '/admin/cliente/consultores', name: 'UserConsultants',
         component: UserCompanies,
         meta: {
            title: 'Consultores',
            requiresAuth: true,
            requiredRoles: [ 'admin', 'super_admin', 'manager']
         },
         props: {
            companyType: 2
         }
      },
      {
         path: '/admin/cliente/proveedores', name: 'UserProviders',
         component: UserCompanies,
         meta: {
            title: 'Proveedores',
            requiresAuth: true,
            requiredRoles: [ 'admin', 'super_admin', 'manager']
         },
         props: {
            companyType: 3
         }
      },
      {
         path: '/admin/cliente/oferta-inmobiliaria', name: 'UserRealEstateOffer',
         component: UserCompanies,
         meta: {
            title: 'Oferta Inmobiliaria',
            requiresAuth: true,
            requiredRoles: [ 'admin', 'super_admin', 'manager']
         },
         props: {
            companyType: 4
         }
      },
      {
         path: '/admin/cliente/empresas/ficha', name: 'UserCompanyData',
         component: CompanyData,
         meta: {
            title: 'Franqucias',
            requiresAuth: true,
            requiredRoles: [ 'admin', 'super_admin', 'manager']
         }
      },
      {
         path: '/admin/empresas/galeria', name: 'CompanyGalery',
         component: CompanyGalery,
         meta: {
            title: 'Galería de Imágenes',
            requiresAuth: true,
            requiredRoles: [ 'admin', 'super_admin']
         }
      },
      {
         path: '/admin/cliente/empresas/galeria', name: 'UserCompanyGalery',
         component: CompanyGalery,
         meta: {
            title: 'Galería de Imágenes',
            requiresAuth: true,
            requiredRoles: [ 'admin', 'super_admin', 'manager']
         }
      },
      {
         path: '/admin/solicitudes', name: 'FormRequests',
         component: FormRequests,
         meta: {
            title: 'Formulario de Solicitudes',
            requiresAuth: true,
            requiredRoles: ['admin', 'super_admin']
         }
      },
      {
         path: '/admin/cliente/solicitudes', name: 'UserFormRequests',
         component: UserFormRequests,
         meta: {
            title: 'Formulario de Solicitudes',
            requiresAuth: true,
            requiredRoles: ['admin', 'super_admin', 'user']
         }
      },
      {
         path: '/admin/publicaciones', name: 'AdminPublications',
         component: Publications,
         meta: {
            title: 'Publicaciones',
            requiresAuth: true,
            requiredRoles: ['admin', 'super_admin']
         }
      },
      {
         path: '/admin/categorias', name: 'Categories',
         component: Categories,
         meta: {
            title: 'Categorias',
            requiresAuth: true,
            requiredRoles: ['admin', 'super_admin']
         }
      },
      {
         path: '/admin/sitemap', name: 'Sitemap',
         component: Sitemap,
         meta: {
            title: 'Sitemap',
            requiresAuth: true,
            requiredRoles: [ 'super_admin' ]
         }
      },
      {
         path: '/admin/glosario', name: 'Glosary',
         component: Glosary,
         meta: {
            title: 'Glosario',
            requiresAuth: true,
            requiredRoles: [ 'super_admin' ]
         }
      },
      {
         path: '/admin/estadisticas', name: 'Statistics',
         component: Statistics,
         meta: {
            title: 'Estadisticas',
            requiresAuth: true,
            requiredRoles: [ 'super_admin' ]
         }
      },
   ]
}
